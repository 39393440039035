import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { isMobile } from 'react-device-detect'

export default function Product() {
  return (
    <>
      <section className="product py-5 position-relative" id="product">
        <div className="container">
          <h2 className="title pb-lg-4">
            <strong>Increase</strong> range,{' '}
            <strong>
              <i>lower</i>
            </strong>{' '}
            emissions, and <br />
            <strong>
              <u>maximize</u>
            </strong>{' '}
            aerodynamic efficiency.
          </h2>

          <div className="row justify-content-center">
            <div className="col-lg-4 mb-3 mb-lg-0">
              <div className="card h-100">
                <div className="card-body">
                  <h3 className="card-title">
                    Automotive Engineering
                    <span className="d-block fw-light fs-6 fw-normal">
                      Cars • Trucks • Motorcycles • Buses and more
                    </span>
                  </h3>
                  <ul className="pt-2 list-unstyled">
                    <li>
                      <span className="fw-bold">Efficiency Optimization</span>
                      <ul className="ps-4 p-0">
                        <li>
                          Reduce aerodynamic drag for lower fuel consumption
                        </li>
                        <li>
                          Extend range electric vehicle range through improved
                          aerodynamics
                        </li>
                        <li>
                          Optimize cooling systems and ducting for
                          cost-effective thermal management
                        </li>
                      </ul>
                    </li>
                    <li className="pt-2">
                      <span className="fw-bold">Performance improvements</span>
                      <ul className="ps-4 p-0">
                        <li>Increase downforce for better handling</li>
                        <li>Fine-tune aerodynamic balance</li>
                        <li>Maximize top speed potential</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-3 mb-lg-0">
              <div className="card h-100">
                <div className="card-body">
                  <h3 className="card-title">
                    Aerospace Solutions
                    <span className="d-block fw-light fs-6 fw-normal">
                      Aircraft • eVTOLs • UAVs • Drones and more
                    </span>
                  </h3>
                  <ul className="pt-2 list-unstyled ">
                    <li>
                      <span className="fw-bold">Flight Performance</span>
                      <ul className="ps-3 pt-1 pb-1">
                        <li>Optimize lift-to-drag ratio for extended range</li>
                        <li>
                          Increase payload capacity through aerodynamic
                          efficiency
                        </li>
                        <li>
                          Analyze and enhance propeller performance integration
                        </li>
                      </ul>
                    </li>
                    <li className="pt-2">
                      <span className="fw-bold">Flight Dynamics</span>
                      <ul className="ps-3 pt-1 pb-1">
                        <li>
                          Comprehensive stability analysis (pitch, roll, yaw)
                        </li>
                        <li>Propulsion-airframe interaction optimization</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-5 pb-4">
            <div className="col-lg-8">
              <div className="table-responsive-sm">
                <table className="table shadow-lg rounded-3">
                  <thead>
                    <tr>
                      <th scope="col">&nbsp;</th>
                      <th
                        scope="col"
                        className="text-center text-secondary fs-5"
                      >
                        Aerodynamics
                      </th>
                      <th
                        scope="col"
                        className="text-center text-secondary fs-5"
                      >
                        Hydrodynamics
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" className="text-end text-secondary">
                        External flow
                      </th>
                      <td>
                        <ul className="ps-3 pt-0">
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Reduce emissions and extend the range of cars,
                                  motorcycles, trucks, and buses by minimizing
                                  aerodynamic drag. Enhance sports car
                                  performance by fine-tuning aero balance and
                                  increasing downforce.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Automotive
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Increase payload, range, and stability of
                                  aircraft, drones, eVTOLs, and UAVs. Analyze
                                  pressure and friction patterns, conduct static
                                  and dynamic stability derivative analyses, and
                                  leverage our shape optimization tools to
                                  maximize your design potential.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Aerospace
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Boost propeller efficiency and thrust, and
                                  maximize the energy capture of turbines.
                                  Analyze velocity patterns and utilize our
                                  propeller coefficient calculations for
                                  optimized design.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Propellers and turbines
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Assess wind flow and pressure patterns around
                                  buildings, solar panels, towers, and more.
                                  Lower peak loads and improve efficiency by
                                  reducing drag through design optimization.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Buildings & Infrastructure
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Enhance athlete and equipment performance by
                                  minimizing aerodynamic drag on helmets,
                                  comparing different riding positions, and
                                  simulating 3D scans.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Sports
                              </span>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul className="ps-3 pt-0">
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Analyze hydrodynamic drag, lift, and moments
                                  on your Autonomous Underwater Vehicle (AUV)
                                  and Remotely Operated Vehicle (ROV) to improve
                                  efficiency, performance, and stability.
                                  Include the flow effects of propellers and
                                  thrusters to understand their interaction with
                                  the main body.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                AUV and ROV
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Increase lift-to-drag ratios and lifting
                                  capacity for hydrofoils and stabilizers to
                                  improve control and efficiency in the water.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Hydrofoils
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Optimize thrust and torque characteristics for
                                  propellers and thrusters using AirShaper’s
                                  rotating element tools. Study interactions
                                  with static elements, such as hulls and
                                  hydrofoils, to further enhance performance and
                                  efficiency.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Propulsion
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Assess hydrodynamic forces on submerged
                                  structures, including offshore infrastructure
                                  and jack-up vessels, to ensure stability and
                                  durability in challenging environments.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Submerged Structures
                              </span>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-end text-secondary">
                        Internal flow
                      </th>
                      <td>
                        <ul className="ps-3 pt-0">
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Lower energy costs by reducing pressure drop
                                  within HVAC systems. Optimize flow
                                  distribution across pipes and rooms with
                                  internal flow analysis tools.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                HVAC Systems
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Analyze manifold flow, reduce exhaust system
                                  back pressure, and more to increase efficiency
                                  and performance.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Automotive Components
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Digitally map fan curves and optimize designs
                                  for improved flow rate and pressure
                                  characteristics.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Fans
                              </span>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul className="ps-3 pt-0">
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Reduce energy costs and optimize flow
                                  distribution by minimizing pressure drop
                                  across pipe networks for more efficient fluid
                                  transport.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Piping Systems
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Map and improve flow rates and pressure
                                  outputs in pump designs. Reduce pressure drops
                                  and fine-tune flow rates through valves to
                                  maximize efficiency and performance.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Pumps and Valves
                              </span>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger
                              key="top"
                              placement={isMobile ? 'top' : 'right'}
                              trigger={['hover', 'focus', 'click']}
                              overlay={
                                <Tooltip id="tooltip-top" className="text-left">
                                  Enhance mixing efficiency by optimizing both
                                  mixer performance and overall basin or tank
                                  flow patterns for consistent, uniform results.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline text-decoration-underline-dotted">
                                Mixers and Agitators
                              </span>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
